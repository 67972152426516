<template>
  <div>
    <v-container fluid grid-list-sm>
      <!-- Segunda/Sexta Menu -->
      <v-layout wrap class="justify-center">
        <v-item-group class="mr-3">
          <v-item>
            <v-checkbox v-model="filtroCheck.h" label="13H"></v-checkbox>
          </v-item>
          <v-item>
            <v-checkbox v-model="filtroCheck.pneus" label="Pneus"></v-checkbox>
          </v-item>
        </v-item-group>
        <v-item-group>
          <v-item>
            <v-select
             placeholder="Zonas"
             :multiple="true"
             :clearable="true"
             item-value="zona_id"
             item-text="zona_nome"
             v-model="zona.value"
             :items="zonasFiltradas"
            >
              <template v-slot:prepend-item>
                <v-text-field style="padding: 15px 25px 0px" v-model="zona.search" placeholder="Pesquisa"></v-text-field>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip>
                  <span>{{ item.zona_nome }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-item>
          <v-item>
            <v-badge
              :value="servicosFiltroTotal(anterior).length > 0"
              color="red"
              right
              overlap
            >
              <template v-slot:badge>
                <span>{{ servicosFiltroTotal(anterior).length }}</span>
              </template>
              <v-btn :depressed="filtro == anterior" @click="filtro = anterior"
                >Atrasados</v-btn
              >
            </v-badge>
          </v-item>
          <v-item>
            <v-badge
              :value="servicosFiltroTotal(segunda).length > 0"
              color="red"
              right
              overlap
            >
              <template v-slot:badge>
                <span>{{ servicosFiltroTotal(segunda).length }}</span>
              </template>
              <v-btn :depressed="filtro == segunda" @click="filtro = segunda"
                >segunda</v-btn
              >
            </v-badge>
          </v-item>
          <v-item>
            <v-badge
              :value="servicosFiltroTotal(terca).length > 0"
              color="red"
              right
              overlap
            >
              <template v-slot:badge>
                <span>{{ servicosFiltroTotal(terca).length }}</span>
              </template>
              <v-btn :depressed="filtro == terca" @click="filtro = terca"
                >terça</v-btn
              >
            </v-badge>
          </v-item>
          <v-item>
            <v-badge
              :value="servicosFiltroTotal(quarta).length > 0"
              color="red"
              right
              overlap
            >
              <template v-slot:badge>
                <span>{{ servicosFiltroTotal(quarta).length }}</span>
              </template>
              <v-btn :depressed="filtro == quarta" @click="filtro = quarta"
                >quarta</v-btn
              >
            </v-badge>
          </v-item>
          <v-item>
            <v-badge
              :value="servicosFiltroTotal(quinta).length > 0"
              color="red"
              right
              overlap
            >
              <template v-slot:badge>
                <span>{{ servicosFiltroTotal(quinta).length }}</span>
              </template>
              <v-btn :depressed="filtro == quinta" @click="filtro = quinta"
                >quinta</v-btn
              >
            </v-badge>
          </v-item>
          <v-item>
            <v-badge
              :value="servicosFiltroTotal(sexta).length > 0"
              color="red"
              right
              overlap
            >
              <template v-slot:badge>
                <span>{{ servicosFiltroTotal(sexta).length }}</span>
              </template>
              <v-btn :depressed="filtro == sexta" @click="filtro = sexta"
                >sexta</v-btn
              >
            </v-badge>
          </v-item>
          <v-item>
            <v-badge
              :value="servicosFiltroTotal(sabado).length > 0"
              color="red"
              right
              overlap
            >
              <template v-slot:badge>
                <span>{{ servicosFiltroTotal(sabado).length }}</span>
              </template>
              <v-btn :depressed="filtro == sabado" @click="filtro = sabado"
                >sábado</v-btn
              >
            </v-badge>
          </v-item>
          <v-item>
            <v-badge
              :value="servicosFiltroTotal(seguinte).length > 0"
              color="red"
              right
              overlap
            >
              <template v-slot:badge>
                <span>{{ servicosFiltroTotal(seguinte).length }}</span>
              </template>
              <v-btn :depressed="filtro == seguinte" @click="filtro = seguinte"
                >Seguinte</v-btn
              >
            </v-badge>
          </v-item>
        </v-item-group>
      </v-layout>
      <!-- Info cada Card  -->
      <v-layout v-if="!loading" wrap>
        <v-flex
          v-for="servico in servicosFiltro"
          :key="'servico-' + servico.chave"
          xs12
          md3
          lg3
        >
          <v-card
            color="blue-grey darken-2"
            class="white--text card-outter"
            width="100%"
            height="100%"
            hover
          >
            <v-card-title primary-title>
              <v-layout wrap>
                <v-flex xs12 sm8>
                  <div v-if="servico.prazo != '0000-00-00'">
                    <span>{{ moment(servico.prazo).format("YYYY-MM-DD") }}</span>
                  </div>
                  <v-divider></v-divider>
                  <div class="headline">
                    <v-layout>
                      <v-flex xs4 style="font-size: 16px;">
                        <span><strong>Nº:</strong></span>
                        {{ servico.cliente }}
                      </v-flex>
                      <v-flex xs4 style="font-size: 16px;">
                        <span><strong>T:</strong></span>
                        <span>{{ servico.tamanho }}''</span>
                      </v-flex>
                      <v-flex xs4 style="font-size: 16px;">
                        <span>Qt:</span>
                        {{ servico.quantidade }}
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-divider></v-divider>
                  <div style="font-size: 16px;">
                    <span class="font-weight-bold">Cor:</span>
                    {{ servico.cor }}
                  </div>
                  <v-divider></v-divider>
                  <div style="font-size: 16px;">
                    <span class="font-weight-bold text-center">Detalhes:</span>
                    <p class="ma-0">{{ servico.descricao }}</p>
                  </div>
                </v-flex>
                <v-flex xs12 sm4 text-xs-center>
                  <div>
                    {{ moment(servico.data).format("YYYY-MM-DD HH:mm:ss") }}
                    <!-- IMG do Produto -->

                    <v-img
                      class="mb-2"
                      @click="aumentoCounter(servico)"
                      :src="urlToken(url + '/servico-imagem/' + servico.chave)"
                      aspect-ratio="1.2"
                    />

                    <!-- Legenda Imagem -->
                    <div class="text-xs-center">
                      <span class="title text-xs-center"
                        >{{ servico.contador }} / {{ servico.quantidade }}</span
                      >
                    </div>
                    <!-- Btn Group -->
                    <v-btn-toggle class="mt-2">
                      <v-btn
                        v-if="id != null"
                        @click="decrementoCounter(servico)"
                        class="v-btn v-btn--active theme--light"
                        block
                        flat
                        :disabled="servico.contador <= 0"
                      >
                        <v-icon color="red">remove</v-icon>
                      </v-btn>
                      <v-btn
                        @click="aumentoCounter(servico)"
                        v-if="id != null"
                        class="v-btn v-btn--active theme--light"
                        block
                        flat
                        :disabled="servico.contador == servico.quantidade"
                      >
                        <v-icon color="green" >add</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <div v-if="servico.nota" class="nota-container">
                    <p class="ma-0">{{ servico.nota }}</p>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-title>
            <!-- SnackBar(é um alerta) -->
            <v-snackbar
              v-model="isContadorErro"
              :bottom="true"
              color="red"
              :timeout="2000"
              >{{ textoErro }}</v-snackbar
            >
            <!-- Btn cada Card -->
            <v-card-actions class="card-actions">
              <template v-for="(produto,index) in servico.departamentos">
                  <template v-if="id && id == produto.departamento_id" >
                    <v-btn
                      :key="'departamento' + servico.chave + '-' + produto.id"
                      @click="showAlertas(servico)"
                      block
                      :color="servico.alerta > 0 ? 'warning' : ''"
                      :flat="!servico.alerta"
                      class="v-btn--active">
                        <v-icon small :color="servico.alerta == 0 ? 'orange' : 'white'">
                          warning
                        </v-icon>
                        <span class="ml-3">Alertas</span>
                    </v-btn>
                          
                    <v-btn
                      :key="'departamento'+ index + '-' + servico.chave + '-' + produto.id"
                      block
                      v-if="id && id == produto.departamento_id"
                      :disabled="produto.id <= 0 || parseInt(servico.quantidade) != parseInt(servico.contador)"
                      @click="changeServico(produto)"
                      :class="{
                      success: produto.estado == 'done',
                      error: produto.estado == 'stop',
                      warning:
                      id &&
                      id == produto.departamento_id &&
                      produto.estado == 'play',
                    }">
                      <v-icon v-if="produto.estado == 'play'" left>
                        play_circle_outline
                      </v-icon>
                      <v-icon v-if="produto.estado == 'stop'" left>stop</v-icon>
                      <v-icon v-if="produto.estado == 'done'" left>check_circle</v-icon>
                      {{ produto.departamento.substr(0, 3) }}
                    </v-btn>
                    
                  </template>
                </template>
              

            </v-card-actions>
          </v-card>
        </v-flex>

        <v-dialog persistent v-model="dialog" width="900">
          <!-- Card Modal -->
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title
              >Alertas: Nº Cliente: {{ servico.cliente }}</v-card-title
            >
            <v-divider></v-divider>
            <div style="padding: 10px">
              <v-btn
                @click="btnNenhumAlerta()"
                block
                :outline="servico.alerta != 0"
                align-center
                class="my-2"
                fluid
              >
                <span>Nenhum</span>
              </v-btn>
              <!-- Alertas no Array -->
              <template v-for="alerta in alertas">
                <v-btn
                  @click="btnAlerta(alerta)"
                  block
                  :outline="alerta.id != servico.alerta"
                  color="#fb8c00"
                  align-center
                  class="my-2"
                  fluid
                  :key="'alerta-' + alerta.id"
                >
                  <span>{{ alerta.descricao }}</span>
                </v-btn>
              </template>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click="dialog = false">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout class="acenter" v-else>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-layout>
    </v-container>
    <v-snackbar v-model="loading" :top="true">Procurar Serviços</v-snackbar>
    <v-snackbar :value="loaded" :top="true" :timeout="6000" color="info"
      >{{ servicosFiltro.length }}/{{ servicos.length }} Serviços</v-snackbar
    >
  </div>
</template>

<style>
.card-outter {
  display: flex;
  flex-direction: column;
}
.card-actions {
  margin-top: auto;
}

.acenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.nota-container {
  background-color: #c62828;
  padding-left: 5px;
  max-width: 100%;
}
</style>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "Departamento",
  data: function () {
    return {
      id: 0,
      filterSheet: false,

      checkedClientes: [],
      checkedZonas: [],
      checkedPrazos: [],
      servicos: [],
      alertas: [],
      filtroCheck: {pneus:false,h:false},
      filtros: {zonas:[]},
      zona:{search:'',value:''},
      toggleBtnVisivel: true,
      isContadorErro: false,
      textoErro: "",
      isContadorSucesso: false,
      selectedWarnings: [],
      departamento: "",
      timer: null,
      dialog: false,
      url: "",
      nrJantesFeito: 0,
      filtro: "",
      anterior: "",
      segunda: "",
      terca: "",
      quarta: "",
      quinta: "",
      sexta: "",
      sabado: "",
      seguinte: "",
      servico: {},
      loading: true,
      errors:[]
    };
  },
  watch: {
    "$route.params.id": function (id) {
      this.id = id;
      this.checkedClientes = [];
      this.checkedZonas = [];
      this.getServicos();
      this.getFiltros();
    },
    'zona.value'(){
      this.getServicos();
    },
    'filtroCheck.pneus'(){
      this.getServicos();
    },
    'filtroCheck.h'(){
      this.getServicos();
    },
    checkedClientes(val) {
      if (val) {
        if (!_.isEmpty(val)) {
          this.checkedZonas = [];
          this.checkedPrazos = []
          this.getServicos();
        }
        if (_.isEmpty(val) && _.isEmpty(this.checkedZonas) && _.isEmpty(this.checkedPrazos)) {
          this.getServicos();
        }
      }
    },
    checkedZonas(val) {
      if (val) {
        if (!_.isEmpty(val)) {
          this.checkedClientes = [];
          this.checkedPrazos = []
          this.getServicos();
        }

        if (_.isEmpty(val) && _.isEmpty(this.checkedClientes) && _.isEmpty(this.checkedPrazos)) {
          this.getServicos();
        }
      }
    },
    checkedPrazos(val){
      if (val) {
        if (!_.isEmpty(val)) {
          this.checkedClientes = [];
          this.checkedZonas = [];
          this.getServicos();
        }

        if (_.isEmpty(val) && _.isEmpty(this.checkedClientes) && _.isEmpty(this.checkedZonas)) {
          this.getServicos();
        }
      }
    }
  },
  async mounted() {
    let self = this;
    self.id = this.$route.params.id;
    self.url = this.$http.defaults.baseURL;

    this.timer = setInterval(async() => {
      this.getAlertas();
      await this.getServicos();
      this.getFiltros();
    }, 80000);

    this.getAlertas();
    await this.getServicos();
    this.getFiltros();  

    moment.locale("pt");

    this.filtro = moment().format("YYYY-MM-DD");

    this.anterior = "anterior";
    this.segunda = moment().startOf("isoweek").format("YYYY-MM-DD");
    this.terca = moment()
      .startOf("isoweek")
      .add(1, "days")
      .format("YYYY-MM-DD");
    this.quarta = moment()
      .startOf("isoweek")
      .add(2, "days")
      .format("YYYY-MM-DD");
    this.quinta = moment()
      .startOf("isoweek")
      .add(3, "days")
      .format("YYYY-MM-DD");
    this.sexta = moment()
      .startOf("isoweek")
      .add(4, "days")
      .format("YYYY-MM-DD");
    this.sabado = moment()
      .startOf("isoweek")
      .add(5, "days")
      .format("YYYY-MM-DD");
    this.seguinte = "seguinte";
  },
  computed: {
    servicosFiltro: function () {
      let este = this;
      return this.servicos.filter((servico) => {
        if (este.filtro == "anterior")
          return moment(servico.prazo).format("YYYY-MM-DD") < este.segunda;
        else if (este.filtro == "seguinte")
          return moment(servico.prazo).format("YYYY-MM-DD") > este.sexta;
        else return este.filtro == moment(servico.prazo).format("YYYY-MM-DD");
      });
    },
    zonasFiltradas(){
      if(this.zona.search !== ''){
        return this.filtros.zonas.filter((zona) =>{
          return zona.zona_nome.toLowerCase().match(this.zona.search.toLowerCase())
        })
      }

      return this.filtros.zonas
    },
    loaded: function () {
      return !this.loading;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getFiltros() {
      this.$http
        .get(`/auth/filtros/departamento/${this.id}`)
        .then((response) => {
          //console.log(response.data)
          this.filtros = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    servicosFiltroTotal: function (filtro) {
      let este = this;
      return este.servicos.filter((servico) => {
        if (filtro == "anterior")
          return moment(servico.prazo).format("YYYY-MM-DD") < este.segunda;
        else if (filtro == "seguinte")
          return moment(servico.prazo).format("YYYY-MM-DD") > este.sabado;
        else return filtro == moment(servico.prazo).format("YYYY-MM-DD");
      });
    },
    aumentoCounter(servico) {
      let self = this;
      let counterAtualizado = parseInt(servico.contador) + 1;

      if (counterAtualizado > servico.quantidade) {
        self.textoErro =
          "Erro! o contador não pode ser maior que a quantidade!";
        self.isContadorErro = true;
      } else {
        var params = {}
        params.departamento = this.id
        this.$http
          .get(
            "/auth/servico-counter/" + servico.chave + "/" + counterAtualizado,{params:params}
          )
          .then(() => {
            //console.log(response.data);
            servico.contador = counterAtualizado;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    decrementoCounter: function (servico) {
      let self = this;
      let counterAtualizado = parseInt(servico.contador) - 1;

      if (counterAtualizado >= 0) {
                var params = {}
        params.departamento = this.id
        this.$http
          .get(
            "/auth/servico-counter/" + servico.chave + "/" + counterAtualizado,{params:params}
          )
          .then(() => {
            //console.log(response.data);
            servico.contador = counterAtualizado;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else {
        self.textoErro = "Erro! o contador não pode ser negativo!";
        self.isContadorErro = true;
        //console.log("counter invalido: ", counterAtualizado);
      }
    },
    btnAlerta: function (alerta) {
      let self = this;

      this.$http
        .get(
          "/auth/servico-select-warning/" + self.servico.chave + "/" + alerta.id
        )
        .then(() => {
          self.servico.alerta = alerta.id;
          //console.log(response.data);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    btnNenhumAlerta: function () {
      let self = this;

      var id = "0";

      this.$http
        .get("/auth/servico-select-warning/" + self.servico.chave + "/" + id)
        .then(() => {
          self.servico.alerta = "0";
          //console.log(response.data);
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    moment(data) {
      return moment(data);
    },
    urlToken: function (url) {
      return url + "?level_token=" + localStorage.getItem("level_token");
    },
    async getServicos() {
      let self = this;
      let params = {};
      var id = "";
      if (self.id) {
        self.loading = true;
        if (!_.isEmpty(this.checkedClientes)) {
          params.clientes = this.checkedClientes;
        }

        params.pneus = this.filtroCheck.pneus
        params.h = this.filtroCheck.h

        if (!_.isEmpty(this.checkedZonas)) {
          params.zonas = this.checkedZonas;
        }

        if (!_.isEmpty(this.checkedPrazos)) {
          params.prazos = this.checkedPrazos;
        }

        if(this.zona.value){
          params.zona = this.zona.value
        }
        
        if(self.id){
          id = "/" + self.id;
        }

        try {
          const response = await this.$http.get("/auth/departamento" + id, { params })
          this.servicos = response.data.servicos;
          this.loading = false;
        } catch (e) {
          this.errors.push(e);
        }
      }
    },
    getAlertas: function () {
      let self = this;
      if (self.id) {
        this.$http
          .get("/auth/servico-alertas")
          .then((response) => {
            //item com id e descricao!
            self.alertas = response.data;
          })
          .catch((e) => {
            //console.log("Erro Alertas, ", e);
            this.errors.push(e);
          });
      }
    },
    showAlertas: function (servico) {
      let self = this;
      self.dialog = true;
      self.servico = servico;
    },
    changeServico: function (produto) {
      let self = this;

      produto.loading = true;
      if (produto.estado == "play") {
        self.$http
          .get("/auth/departamento-start/" + produto.id)
          .then(() => {
            self.getServicos();
            produto.loading = false;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
      if (produto.estado == "stop") {
        self.$http
          .get("/auth/departamento-stop/" + produto.id)
          .then(() => {
            produto.loading = false;
            self.getServicos()
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
  },
};
</script>